<template>
  <div>
    <NuxtLayout>
      <!-- <NuxtLoadingIndicator :height="1" :color="'#fff'" class="opacity-15" /> -->
      <!-- 
      <div v-if="loadingstate" class="h-screen w-full fixed left-0 top-0 bottom-0 right-0 z-50 bg-page-dark flex items-center justify-center">
        <UiLoadSpinner />
      </div>
      -->
      <!-- <VitePwaManifest /> -->
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>

  // pinia stores
  import { storeToRefs } from 'pinia';
  import {  useLoadingStateStore } from '@/store/loading';
  const loadingStateStore = useLoadingStateStore();
  const { loadingstate } = storeToRefs(loadingStateStore);

  // loading
  const nuxtApp = useNuxtApp();
  nuxtApp.hook("page:start", () => {
    loadingstate.value = true;
  });
  nuxtApp.hook("page:finish", () => {
    loadingstate.value = false;
  });
 
  // nyelvi es routing beallitasok
  const { locale } = useI18n();

  useHead({
    // as a string, where `%s` is replaced with the title
    htmlAttrs: {
      lang: locale.value,
    },
    // titleTemplate: 'Bestsellers.hu %s',
    link: [
      {
        rel: 'preconnect',
        href: 'https://fonts.googleapis.com'
      },
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
        crossorigin: ''
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/apple-touch-icon.png'
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicon-32x32.png'
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicon-16x16.png'
      },
      {
        rel: 'manifest',
        href: '/site.webmanifest'
      },
    ],
    script: [
      // {
      //   src: 'https://app.virtualjog.hu/widget/index.js?v=5',
      //   id: 'vj-widget-1059c32a',
      //   'access-token': '6b5e9a99ac5ecb41989b163b9b9267fc',
      //   'domain': 'bestsellers.hu',
      //   // lang: 'en',
      //   // ...(locale.value === 'en' ? { lang: 'en' } : {}),
      //   tagPosition: 'bodyClose',
      //   // async: true,
      //   // innerHTML: ``,
      // },
      // {
      //   type: 'text/javascript',
      //   src: 'https://app.virtualjog.hu/widget/index.js?v=5',
      //   id: 'vj-widget-1059c32a',
      //   async: false,
      //   defer: true,
      //   'access-token': '6b5e9a99ac5ecb41989b163b9b9267fc',
      //   domain: 'bestsellers.hu',
      //   tagPosition: 'bodyOpen',
      //   once : true,
      // },
      {
        type: 'text/javascript',
        innerHTML: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied',
            'wait_for_update': 1500,
          });
        `,
        tagPosition: 'head',
        // charset: 'utf-8'
      },
      {
        type: 'text/javascript',
        innerHTML: `// Create BP element on the window
  window["bp"] = window["bp"] || function () {
    (window["bp"].q = window["bp"].q || []).push(arguments);
  };
  window["bp"].l = 1 * new Date();

  // Insert a script tag on the top of the head to load bp.js
  scriptElement = document.createElement("script");
  firstScript = document.getElementsByTagName("script")[0];
  scriptElement.async = true;
  scriptElement.src = 'https://pixel.barion.com/bp.js';
  firstScript.parentNode.insertBefore(scriptElement, firstScript);
  window['barion_pixel_id'] = 'BP-MtPaNmLa2I-55';

  // Send init event
  bp('init', 'addBarionPixelId', window['barion_pixel_id']);
  window["bptest"] = function () {
    alert('done');
  }`,
        // tagPosition: 'bodyClose | bodyOpen | head',
        tagPosition: 'head',
      },
    ],
    noscript: [
      {
        innerHTML: `<img height="1" width="1" style="display:none" alt="Barion Pixel" src="https://pixel.barion.com/a.gif?ba_pixel_id='BP-MtPaNmLa2I-55'&ev=contentView&noscript=1">`
      }
    ],
  })
</script>

<style>
  .page-enter-active,
  .page-leave-active {
    transition: all 0.4s;
  }
  .page-enter-from,
  .page-leave-to {
    opacity: 0;
    filter: blur(1rem);
  }
</style>