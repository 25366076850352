export const createLogPasswordReminder = /* GraphQL */ `
  mutation CreateLogPasswordReminder(
    $input: CreateLogPasswordReminderInput!
    $condition: ModelLogPasswordReminderConditionInput
  ) {
    createLogPasswordReminder(input: $input, condition: $condition) {
      id
      email
      info
      createdAt
      updatedAt
      __typename
    }
  }
`;