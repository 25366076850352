export default defineNuxtPlugin((nuxtApp) => {

  // nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
  //   // handle error, e.g. report to a service
  //   console.log({error, info});
  // }

  // error info kiiratasa
  nuxtApp.hook('vue:error', (error, instance, info) => {
    // handle error, e.g. report to a service
    console.log({error, instance, info});
  })

})