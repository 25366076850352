import { Hub } from 'aws-amplify';
// import { storeToRefs } from 'pinia';
import { useAuthStore } from "~/store/auth";

export default defineNuxtPlugin(nuxtApp => {
  // console.log('authHub plugin');

  // auth
  const authStore = useAuthStore();
  const { resetAuth } = authStore;
  // const { authenticated, username, actionerror } = storeToRefs(authStore);

  const listener = (data) => {
    // console.log('auth hub event: ', data?.payload?.event);
    switch (data?.payload?.event) {
      case 'configured':
        console.log('Listener: the Auth module is configured');
        break;
      case 'signIn':
        console.log('Listener: user signed in');
        break;
      case 'signIn_failure':
        console.log('Listener: user sign in failed');
        break;
      case 'signUp':
        console.log('Listener: user signed up');
        break;
      case 'signUp_failure':
        console.log('Listener: user sign up failed');
        break;
      case 'confirmSignUp':
        console.log('Listener: user confirmation successful');
        break;
      case 'completeNewPassword_failure':
        console.log('Listener: user did not complete new password flow');
        break;
      case 'autoSignIn':
        console.log('Listener: auto sign in successful');
        break;
      case 'autoSignIn_failure':
        console.log('Listener: auto sign in failed');
        break;
      case 'forgotPassword':
        console.log('Listener: password recovery initiated');
        break;
      case 'forgotPassword_failure':
        console.log('Listener: password recovery failed');
        break;
      case 'forgotPasswordSubmit':
        console.log('Listener: password confirmation successful');
        break;
      case 'forgotPasswordSubmit_failure':
        console.log('Listener: password confirmation failed');
        break;
      case 'verify':
        console.log('Listener: TOTP token verification successful');
        break;
      case 'tokenRefresh':
        console.log('Listener: token refresh succeeded');
        break;
      case 'tokenRefresh_failure':
        console.log('Listener: token refresh failed');
        break;
      case 'cognitoHostedUI':
        console.log('Listener: Cognito Hosted UI sign in successful');
        break;
      case 'cognitoHostedUI_failure':
        console.log('Listener: Cognito Hosted UI sign in failed');
        break;
      case 'customOAuthState':
        console.log('Listener: custom state returned from CognitoHosted UI');
        break;
      case 'customState_failure':
        console.log('Listener: custom state failure');
        break;
      case 'parsingCallbackUrl':
        console.log('Listener: Cognito Hosted UI OAuth url parsing initiated');
        break;
      case 'userDeleted':
        console.log('Listener: user deletion successful');
        break;
      case 'updateUserAttributes':
        console.log('Listener: user attributes update successful');
        break;
      case 'updateUserAttributes_failure':
        console.log('Listener: user attributes update failed');
        break;
      case 'signOut':
        resetAuth();
        console.log('Listener: user signed out');
        break;
      default:
        console.log('Listener: unknown event type');
        break;
    }
    // console.log('authHub: authenticated', authenticated);
    // console.log('authHub: username', username);
  };
  
  Hub.listen('auth', listener);

});
