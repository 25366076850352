import { default as _91_46_46_46catchAll_93iua1NJIpDvMeta } from "/codebuild/output/src3997706904/src/storefront/pages/[...catchAll].vue?macro=true";
import { default as about_45usaYeEHVnK0AMeta } from "/codebuild/output/src3997706904/src/storefront/pages/about-us.vue?macro=true";
import { default as barion_45startYDZQVU6bvmMeta } from "/codebuild/output/src3997706904/src/storefront/pages/barion-start.vue?macro=true";
import { default as bestsellers_45of_45the_45monthOwSvuU7WpmMeta } from "/codebuild/output/src3997706904/src/storefront/pages/bestsellers-of-the-month.vue?macro=true";
import { default as book_45clubVmXOL6f3bIMeta } from "/codebuild/output/src3997706904/src/storefront/pages/book-club.vue?macro=true";
import { default as _91slug_936XjAswe0vjMeta } from "/codebuild/output/src3997706904/src/storefront/pages/book/[slug].vue?macro=true";
import { default as _91slug_93FcNr5EcNY9Meta } from "/codebuild/output/src3997706904/src/storefront/pages/books-by-author/[slug].vue?macro=true";
import { default as index9ap74CAYdMMeta } from "/codebuild/output/src3997706904/src/storefront/pages/books/[category]/[subcategory]/index.vue?macro=true";
import { default as index0hROKpMK1ZMeta } from "/codebuild/output/src3997706904/src/storefront/pages/books/[category]/index.vue?macro=true";
import { default as index16k1Sx2gXiMeta } from "/codebuild/output/src3997706904/src/storefront/pages/books/index.vue?macro=true";
import { default as indexQtsDOX3kR0Meta } from "/codebuild/output/src3997706904/src/storefront/pages/checkout/index.vue?macro=true";
import { default as thankyouH9Bb3gKwR4Meta } from "/codebuild/output/src3997706904/src/storefront/pages/checkout/thankyou.vue?macro=true";
import { default as contact_45us7hAfJbcQTiMeta } from "/codebuild/output/src3997706904/src/storefront/pages/contact-us.vue?macro=true";
import { default as cookieNsFPc2YfEXMeta } from "/codebuild/output/src3997706904/src/storefront/pages/cookie.vue?macro=true";
import { default as index5tf7p6QJSnMeta } from "/codebuild/output/src3997706904/src/storefront/pages/index.vue?macro=true";
import { default as loginWx2GUJyuE4Meta } from "/codebuild/output/src3997706904/src/storefront/pages/login.vue?macro=true";
import { default as _91slug_93dFdJDnl3n1Meta } from "/codebuild/output/src3997706904/src/storefront/pages/menu-navigation/[slug].vue?macro=true";
import { default as my_45cartCzMrGAgLEDMeta } from "/codebuild/output/src3997706904/src/storefront/pages/my-cart.vue?macro=true";
import { default as _91slug_93936Nw86e71Meta } from "/codebuild/output/src3997706904/src/storefront/pages/my-orders/[slug].vue?macro=true";
import { default as indexSDTkn0d8CpMeta } from "/codebuild/output/src3997706904/src/storefront/pages/my-orders/index.vue?macro=true";
import { default as newly_45publishedDkLhzkoRxcMeta } from "/codebuild/output/src3997706904/src/storefront/pages/newly-published.vue?macro=true";
import { default as newspapersWqLzt7AQxFMeta } from "/codebuild/output/src3997706904/src/storefront/pages/newspapers.vue?macro=true";
import { default as on_45saleN6lDpRvwrzMeta } from "/codebuild/output/src3997706904/src/storefront/pages/on-sale.vue?macro=true";
import { default as _91slug_93HL6PwUBZEKMeta } from "/codebuild/output/src3997706904/src/storefront/pages/other-language/[slug].vue?macro=true";
import { default as indexn2lmPADxplMeta } from "/codebuild/output/src3997706904/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue?macro=true";
import { default as indexnfkJXcW9pAMeta } from "/codebuild/output/src3997706904/src/storefront/pages/other-languages/[category]/index.vue?macro=true";
import { default as indexEtG2muZawdMeta } from "/codebuild/output/src3997706904/src/storefront/pages/other-languages/index.vue?macro=true";
import { default as payment_45resultk1DBzAIIbuMeta } from "/codebuild/output/src3997706904/src/storefront/pages/payment-result.vue?macro=true";
import { default as paypal_45startLK1FINtLoLMeta } from "/codebuild/output/src3997706904/src/storefront/pages/paypal-start.vue?macro=true";
import { default as privacy_45policy7plYnQB8TUMeta } from "/codebuild/output/src3997706904/src/storefront/pages/privacy-policy.vue?macro=true";
import { default as _91_91subcategory_93_93VjmmtzlktFMeta } from "/codebuild/output/src3997706904/src/storefront/pages/products/[[category]]/[[subcategory]].vue?macro=true";
import { default as purchase_45and_45shipping_45conditionsa9nebt5cpXMeta } from "/codebuild/output/src3997706904/src/storefront/pages/purchase-and-shipping-conditions.vue?macro=true";
import { default as searcha4i9oauAyMMeta } from "/codebuild/output/src3997706904/src/storefront/pages/search.vue?macro=true";
import { default as staff_45recommendations1BHsylk9XAMeta } from "/codebuild/output/src3997706904/src/storefront/pages/staff-recommendations.vue?macro=true";
import { default as _91slug_93gI1GKCjYwiMeta } from "/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gift/[slug].vue?macro=true";
import { default as indexVTYe9dXMN1Meta } from "/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue?macro=true";
import { default as indexAxaWcNHSozMeta } from "/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gifts/[category]/index.vue?macro=true";
import { default as index6AYTJtE8EWMeta } from "/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gifts/index.vue?macro=true";
import { default as terms_45and_45conditionsG2ftwCoj5mMeta } from "/codebuild/output/src3997706904/src/storefront/pages/terms-and-conditions.vue?macro=true";
import { default as upcoming_45releasescH5PXoNnXxMeta } from "/codebuild/output/src3997706904/src/storefront/pages/upcoming-releases.vue?macro=true";
export default [
  {
    name: "catchAll___hu",
    path: "/hu/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93iua1NJIpDvMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/[...catchAll].vue")
  },
  {
    name: "catchAll___en",
    path: "/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93iua1NJIpDvMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/[...catchAll].vue")
  },
  {
    name: "about-us___hu",
    path: "/hu/rolunk",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/about-us.vue")
  },
  {
    name: "about-us___en",
    path: "/about-us",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/about-us.vue")
  },
  {
    name: "barion-start___hu",
    path: "/hu/barion-fizetes",
    meta: barion_45startYDZQVU6bvmMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/barion-start.vue")
  },
  {
    name: "barion-start___en",
    path: "/barion-start",
    meta: barion_45startYDZQVU6bvmMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/barion-start.vue")
  },
  {
    name: "bestsellers-of-the-month___hu",
    path: "/hu/a-honap-sikerkonyvei",
    meta: bestsellers_45of_45the_45monthOwSvuU7WpmMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/bestsellers-of-the-month.vue")
  },
  {
    name: "bestsellers-of-the-month___en",
    path: "/bestsellers-of-the-month",
    meta: bestsellers_45of_45the_45monthOwSvuU7WpmMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/bestsellers-of-the-month.vue")
  },
  {
    name: "book-club___hu",
    path: "/hu/konyvklub",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/book-club.vue")
  },
  {
    name: "book-club___en",
    path: "/book-club",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/book-club.vue")
  },
  {
    name: "book-slug___hu",
    path: "/hu/konyv/:slug()",
    meta: _91slug_936XjAswe0vjMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/book/[slug].vue")
  },
  {
    name: "book-slug___en",
    path: "/book/:slug()",
    meta: _91slug_936XjAswe0vjMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/book/[slug].vue")
  },
  {
    name: "books-by-author-slug___hu",
    path: "/hu/szerzo-muvei/:slug()",
    meta: _91slug_93FcNr5EcNY9Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/books-by-author/[slug].vue")
  },
  {
    name: "books-by-author-slug___en",
    path: "/books-by-author/:slug()",
    meta: _91slug_93FcNr5EcNY9Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/books-by-author/[slug].vue")
  },
  {
    name: "books-category-subcategory___hu",
    path: "/hu/konyvek/:category()/:subcategory()",
    meta: index9ap74CAYdMMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/books/[category]/[subcategory]/index.vue")
  },
  {
    name: "books-category-subcategory___en",
    path: "/books/:category()/:subcategory()",
    meta: index9ap74CAYdMMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/books/[category]/[subcategory]/index.vue")
  },
  {
    name: "books-category___hu",
    path: "/hu/konyvek/:category()",
    meta: index0hROKpMK1ZMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/books/[category]/index.vue")
  },
  {
    name: "books-category___en",
    path: "/books/:category()",
    meta: index0hROKpMK1ZMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/books/[category]/index.vue")
  },
  {
    name: "books___hu",
    path: "/hu/konyvek",
    meta: index16k1Sx2gXiMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/books/index.vue")
  },
  {
    name: "books___en",
    path: "/books",
    meta: index16k1Sx2gXiMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/books/index.vue")
  },
  {
    name: "checkout___hu",
    path: "/hu/megrendeles",
    meta: indexQtsDOX3kR0Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/checkout/index.vue")
  },
  {
    name: "checkout___en",
    path: "/checkout",
    meta: indexQtsDOX3kR0Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/checkout/index.vue")
  },
  {
    name: "checkout-thankyou___hu",
    path: "/hu/megrendeles-koszonjuk",
    meta: thankyouH9Bb3gKwR4Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/checkout/thankyou.vue")
  },
  {
    name: "checkout-thankyou___en",
    path: "/checkout-thankyou",
    meta: thankyouH9Bb3gKwR4Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/checkout/thankyou.vue")
  },
  {
    name: "contact-us___hu",
    path: "/hu/kapcsolat",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/contact-us.vue")
  },
  {
    name: "contact-us___en",
    path: "/contact-us",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/contact-us.vue")
  },
  {
    name: "cookie___hu",
    path: "/hu/suti",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/cookie.vue")
  },
  {
    name: "cookie___en",
    path: "/cookie",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/cookie.vue")
  },
  {
    name: "index___hu",
    path: "/hu",
    meta: index5tf7p6QJSnMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: index5tf7p6QJSnMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/index.vue")
  },
  {
    name: "login___hu",
    path: "/hu/bejelentkezes",
    meta: loginWx2GUJyuE4Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginWx2GUJyuE4Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/login.vue")
  },
  {
    name: "menu-navigation-slug___hu",
    path: "/hu/menu-navigacio/:slug()",
    meta: _91slug_93dFdJDnl3n1Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/menu-navigation/[slug].vue")
  },
  {
    name: "menu-navigation-slug___en",
    path: "/menu-navigation/:slug()",
    meta: _91slug_93dFdJDnl3n1Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/menu-navigation/[slug].vue")
  },
  {
    name: "my-cart___hu",
    path: "/hu/kosaram",
    meta: my_45cartCzMrGAgLEDMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/my-cart.vue")
  },
  {
    name: "my-cart___en",
    path: "/my-cart",
    meta: my_45cartCzMrGAgLEDMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/my-cart.vue")
  },
  {
    name: "my-orders-slug___hu",
    path: "/hu/rendeleseim/:slug()",
    meta: _91slug_93936Nw86e71Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/my-orders/[slug].vue")
  },
  {
    name: "my-orders-slug___en",
    path: "/my-orders/:slug()",
    meta: _91slug_93936Nw86e71Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/my-orders/[slug].vue")
  },
  {
    name: "my-orders___hu",
    path: "/hu/rendeleseim",
    meta: indexSDTkn0d8CpMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/my-orders/index.vue")
  },
  {
    name: "my-orders___en",
    path: "/my-orders",
    meta: indexSDTkn0d8CpMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/my-orders/index.vue")
  },
  {
    name: "newly-published___hu",
    path: "/hu/ujdonsagok",
    meta: newly_45publishedDkLhzkoRxcMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/newly-published.vue")
  },
  {
    name: "newly-published___en",
    path: "/newly-published",
    meta: newly_45publishedDkLhzkoRxcMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/newly-published.vue")
  },
  {
    name: "newspapers___hu",
    path: "/hu/ujsagok-es-magazinok",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/newspapers.vue")
  },
  {
    name: "newspapers___en",
    path: "/newspapers-and-magazines",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/newspapers.vue")
  },
  {
    name: "on-sale___hu",
    path: "/hu/akciok",
    meta: on_45saleN6lDpRvwrzMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/on-sale.vue")
  },
  {
    name: "on-sale___en",
    path: "/on-sale",
    meta: on_45saleN6lDpRvwrzMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/on-sale.vue")
  },
  {
    name: "other-language-slug___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyv/:slug()",
    meta: _91slug_93HL6PwUBZEKMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/other-language/[slug].vue")
  },
  {
    name: "other-language-slug___en",
    path: "/other-language/:slug()",
    meta: _91slug_93HL6PwUBZEKMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/other-language/[slug].vue")
  },
  {
    name: "other-languages-category-subcategory___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:categorysubcategory?",
    meta: indexn2lmPADxplMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue")
  },
  {
    name: "other-languages-category-subcategory___en",
    path: "/other-languages/:category()/:subcategory()",
    meta: indexn2lmPADxplMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue")
  },
  {
    name: "other-languages-category___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:category()",
    meta: indexnfkJXcW9pAMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/other-languages/[category]/index.vue")
  },
  {
    name: "other-languages-category___en",
    path: "/other-languages/:category()",
    meta: indexnfkJXcW9pAMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/other-languages/[category]/index.vue")
  },
  {
    name: "other-languages___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek",
    meta: indexEtG2muZawdMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/other-languages/index.vue")
  },
  {
    name: "other-languages___en",
    path: "/other-languages",
    meta: indexEtG2muZawdMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/other-languages/index.vue")
  },
  {
    name: "payment-result___hu",
    path: "/hu/barion-eredmeny",
    meta: payment_45resultk1DBzAIIbuMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/payment-result.vue")
  },
  {
    name: "payment-result___en",
    path: "/barion-result",
    meta: payment_45resultk1DBzAIIbuMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/payment-result.vue")
  },
  {
    name: "paypal-start___hu",
    path: "/hu/paypal-fizetes",
    meta: paypal_45startLK1FINtLoLMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/paypal-start.vue")
  },
  {
    name: "paypal-start___en",
    path: "/paypal-start",
    meta: paypal_45startLK1FINtLoLMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/paypal-start.vue")
  },
  {
    name: "privacy-policy___hu",
    path: "/hu/adatkezelesi-tajekoztato",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/privacy-policy.vue")
  },
  {
    name: "products-category-subcategory___hu",
    path: "/hu/product/:category?/:subcategory?",
    meta: _91_91subcategory_93_93VjmmtzlktFMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/products/[[category]]/[[subcategory]].vue")
  },
  {
    name: "products-category-subcategory___en",
    path: "/products/:category?/:subcategory?",
    meta: _91_91subcategory_93_93VjmmtzlktFMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/products/[[category]]/[[subcategory]].vue")
  },
  {
    name: "purchase-and-shipping-conditions___hu",
    path: "/hu/vasarlasi-es-szallitasi-feltetelek",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/purchase-and-shipping-conditions.vue")
  },
  {
    name: "purchase-and-shipping-conditions___en",
    path: "/purchase-and-shipping-conditions",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/purchase-and-shipping-conditions.vue")
  },
  {
    name: "search___hu",
    path: "/hu/kereses",
    meta: searcha4i9oauAyMMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/search",
    meta: searcha4i9oauAyMMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/search.vue")
  },
  {
    name: "staff-recommendations___hu",
    path: "/hu/mi-ajanljuk",
    meta: staff_45recommendations1BHsylk9XAMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/staff-recommendations.vue")
  },
  {
    name: "staff-recommendations___en",
    path: "/staff-recommendations",
    meta: staff_45recommendations1BHsylk9XAMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/staff-recommendations.vue")
  },
  {
    name: "stationary-and-gift-slug___hu",
    path: "/hu/notesz-es-ajandektargy/:slug()",
    meta: _91slug_93gI1GKCjYwiMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gift/[slug].vue")
  },
  {
    name: "stationary-and-gift-slug___en",
    path: "/stationary-and-gift/:slug()",
    meta: _91slug_93gI1GKCjYwiMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gift/[slug].vue")
  },
  {
    name: "stationary-and-gifts-category-subcategory___hu",
    path: "/hu/noteszek-es-ajandektargyak/:categorysubcategory?",
    meta: indexVTYe9dXMN1Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue")
  },
  {
    name: "stationary-and-gifts-category-subcategory___en",
    path: "/stationary-and-gifts/:category()/:subcategory()",
    meta: indexVTYe9dXMN1Meta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue")
  },
  {
    name: "stationary-and-gifts-category___hu",
    path: "/hu/noteszek-es-ajandektargyak/:category()",
    meta: indexAxaWcNHSozMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gifts/[category]/index.vue")
  },
  {
    name: "stationary-and-gifts-category___en",
    path: "/stationary-and-gifts/:category()",
    meta: indexAxaWcNHSozMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gifts/[category]/index.vue")
  },
  {
    name: "stationary-and-gifts___hu",
    path: "/hu/noteszek-es-ajandektargyak",
    meta: index6AYTJtE8EWMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gifts/index.vue")
  },
  {
    name: "stationary-and-gifts___en",
    path: "/stationary-and-gifts",
    meta: index6AYTJtE8EWMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/stationary-and-gifts/index.vue")
  },
  {
    name: "terms-and-conditions___hu",
    path: "/hu/altalanos-szerzodesi-feltetelek",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/terms-and-conditions",
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/terms-and-conditions.vue")
  },
  {
    name: "upcoming-releases___hu",
    path: "/hu/varhato-megjelenesek",
    meta: upcoming_45releasescH5PXoNnXxMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/upcoming-releases.vue")
  },
  {
    name: "upcoming-releases___en",
    path: "/upcoming-releases",
    meta: upcoming_45releasescH5PXoNnXxMeta || {},
    component: () => import("/codebuild/output/src3997706904/src/storefront/pages/upcoming-releases.vue")
  }
]