export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"fb-app_id","property":"fb:app_id","content":"966242223397117"},{"hid":"og-site_name","property":"og:site_name","content":"Bestsellers.hu"},{"hid":"og-type","property":"og:type","content":"website"},{"hid":"og-title","property":"og:title","content":"Bestsellers.hu"},{"hid":"og-desc","property":"og:description","content":"The best foreign-language bookstore in Budapest with the largest selection of English, French, German, Italian, Russian and Spanish books, newspapers and magazines."},{"hid":"og-image","property":"og:image","content":"https://bestsellers.hu/bestsellers.jpg"},{"hid":"description","name":"description","content":"The best foreign-language bookstore in Budapest with the largest selection of English, French, German, Italian, Russian and Spanish books, newspapers and magazines."}],"link":[],"style":[],"script":[{"src":"https://app.virtualjog.hu/widget/index.js?v=7","id":"vj-widget-1059c32a","access-token":"6b5e9a99ac5ecb41989b163b9b9267fc","domain":"bestsellers.hu","body":true},{"innerHTML":"\n(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-MR37VK5K');\n","type":"text/javascript"}],"noscript":[{"innerHTML":"\n          <iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-MR37VK5K\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>\n          ","tagPosition":"bodyOpen"}],"charset":"utf-8","viewport":"width=device-width, initial-scale=1","titleTemplate":"Bestsellers %s"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false