/**
 * 
 * Pinia Store: webshop kosár modal ablak allapota
 * 
 * 
*/
import { defineStore } from 'pinia';
import { Auth, API } from 'aws-amplify';
import { createLogPasswordReminder } from '~/graphql/mutation-forgot-password-log'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authenticated: false,
    user: null,
    userid: null,
    username: null,
    userstatus: null,
    usergroups: [],
    email: null,
    authorizeLevel: 0,
    loading: false,
    actionerror: '',
    actionerrordetails: '',
  }),
  getters: {
    getAuthorizeLevel() {
      return this.authorizeLevel;
    },
    getAuthenticated() {
      return this.authenticated;
    },
    getUserGroups() {
      return this.usergroups;
    },
  },
  actions: {
    setAuthenticated(authenticated) {
      this.authenticated = authenticated;
    },
    setAuthorizeLevel(level) {
      this.authorizeLevel = level;
    },
    setActionError(msg) {
      this.actionerror = msg;
    },
    clearErrors() {
      this.actionerror = '';
      this.actionerrordetails = '';
    },
    // egyelore nem hasznaljuk:
    async currentAuthenticatedUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        this.user = user;
        this.setAuthenticated(true);
        this.authorizeLevel = 0;
        this.username = user.username;
        this.userid = user.attributes.sub;
        this.email = user.attributes.email;
        return user
      } catch (error) {
        this.resetAuth();
      }
      console.log('cau', this.authenticated);
    },
    // a pinia store "authenticated" state közvetlen elérése helyett:
    async isAuthenticated() {
      console.log('isAuthenticated called');
      try {
        const user = await Auth.currentAuthenticatedUser();
        this.user = user;
        this.setAuthenticated(true);
        this.authorizeLevel = 0;
        this.username = user.username;
        this.userid = user.attributes.sub;
        this.email = user.attributes.email;
        // return user
        return true
      } catch (error) {
        this.resetAuth();
        return false
      }
      console.log('cau', this.authenticated);
    },
    async signUpUser(username, password, email) {
      console.log('signUpUser: ', {username, password, email});
      try {
        // regisztráció
        const signupresult = await Auth.signUp({
          username,
          password,
          attributes: {
            email, // optional
            // phone_number, // optional - E.164 number convention
            // other custom attributes
          },
        });
        // TODO:
        // ez itt még nem biztos h okés:
        console.log(signupresult);

        this.user = signupresult;
        this.setAuthenticated(true);
        this.authorizeLevel = 0;
        this.username = signupresult.username;
        // this.userid = signupresult.attributes.sub;
        this.userid = signupresult.userSub;
        // this.email = signupresult.attributes.email;        
        this.email = signupresult.username;
        return signupresult;
      } catch (error) {
        console.log('pinia signUpUser info (nem hiba): ', error);
        // console.log('pinia signUpUser error.code: ', error.code);
        throw error;
      }
      // Google Analytics regisztráció event:
      // csak production környezetben van követőkód (lásd vue-gtag plugin config)
// TODO:
      // az analitikai dolgokat egy másik store-ba kell rakni (fontos hogy csak kliens oldal!!)
      // analitika
      // const { gtag } = useGtag()
      //       // (SSR-ready)
      //       gtag('event', 'sign_up', {
      //         // app_name: 'Bestsellers.hu',
      //         // screen_name: 'Home'
      //         event_category: 'engagement',
      //         event_label: 'sign_up',
      //         method: 'Cognito',
      //       });
      // if (process.env.NODE_ENV === 'production') {
      //   this._vm.$gtag.event('sign_up', {
      //     method: 'Cognito',
      //   })
      // }
    },
    // TODO:
    //  felülvizsgálni:
    async completeNewPassword(username, password, newPassword, name) {
      await Auth.signIn(username, password)
        .then((user) => {
          const { requiredAttributes } = user.challengeParam // the array of required attributes, e.g ['email', 'phone_number']
          // eslint-disable-next-line no-console
          console.log(requiredAttributes)
          Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword, // the new password
            // OPTIONAL, the required attributes
            {
              email: user.email,
              name,
            }
          )
          .then((user) => {
            // commit('set', user)
            this.user = user;
            return user
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
          })
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
    // Send confirmation code to user's email
    async forgotPassword(username) {
      // elfelejtett jelszó igény/kérés naplózása
      try {
        await API.graphql({
          query: createLogPasswordReminder,
          variables: { 
            input: {
              email: username || '',
              info: '',
            }
          },
          authMode: this.authenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
          // authMode: 'AWS_IAM',
        });
      } catch (error) {
        console.log('createLogPasswordReminder error:', error);
      } finally {
        // elfelejtett jelszó: e-mail küldése a visszaállító kóddal
        return await Auth.forgotPassword(username);
      }
    },
      // Collect confirmation code and new password, then
    async forgotPasswordSubmit(username, code, newPassword) {
      // elfelejtett jelszó igény/kérés naplózása
      // try {
      //   await API.graphql({
      //     query: createLogPasswordReminder,
      //     variables: { 
      //       input: {
      //         email: username || '',
      //         info: 'code: ' + (code || '') + ', newPassword: ' + newPassword,
      //       }
      //     },
      //     authMode: this.authenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
      //     // authMode: 'AWS_IAM',
      //   });
      // } catch (error) {
      //   console.log('createLogPasswordReminder error:', error);
      // } finally {
      // elfelejtett jelszó után új jelszó beállítása:
      return await Auth.forgotPasswordSubmit(username, code, newPassword);
      // }
    },
    async authenticateUser(username, password) {
      this.loading = true;
      console.log('pinia authenticateUser called');
      try {
        // const nuxt = useNuxtApp();
        const userAuthSignIn = await Auth.signIn(username, password);
        console.log('userAuthSignIn data: ', userAuthSignIn);
        // 1. ha sikerult az auth, akkor a this.authenticated = true;
        // 2. frissíteni a this.authorizeLevel -t a group alapján.
        // 3. plussz itt lehetne megvalósítani a "kötelező a jelszóváltoztatás, stb. részt"
        if(userAuthSignIn) {
          // this.authenticated = true;
          this.setAuthenticated(true);
          this.authorizeLevel = 0;
          this.user = userAuthSignIn; // ez tárol mindent, de törekedni kell a kivezetésére
          this.userstatus = userAuthSignIn.challengeName ? userAuthSignIn.challengeName : null;
          this.username = userAuthSignIn.username;
          this.userid = userAuthSignIn.attributes.sub;
          this.email = userAuthSignIn.attributes.email;
          // user csoportok:
          if(userAuthSignIn.signInUserSession.accessToken.payload['cognito:groups']) {
            this.usergroups = userAuthSignIn.signInUserSession.accessToken.payload['cognito:groups'];
          }
          // this.emailverified = userAuthSignIn.attributes.email_verified;
          console.log('pinia authenticateUser result: ', userAuthSignIn);
// TODO: 
//  1.a) paraméter alapján legyen eldöntve, hogy erre szükség van-e itt, vagy az adott oldal speciálisan kezeli
//  1.b) b verzió?
//  2. kellene egy külön változó, ahol a challengeName-t eltároljuk és a user "állapotát"
          // switch (userAuthSignIn.challengeName) {
          //   /*
          //   case 'SMS_MFA':
          //     // 'You need to input the code received from SMS message'
          //     break
          //   case 'SOFTWARE_TOKEN_MFA':
          //     // 'You need to input the OTP(one time password).'
          //     break
          //   */
          //   case 'NEW_PASSWORD_REQUIRED':
          //     console.log('NEW_PASSWORD_REQUIRED');
          //     // completeNewPwd.value = true
          //     break
          //   // todo
          //   case 'FORCE_CHANGE_PASSWORD':
          //     console.log('FORCE_CHANGE_PASSWORD');
          //     // completeNewPwd.value = true
          //     // await navigateTo(localePath('/forgot-password'));
          //     await navigateTo(nuxt.$localePath('/forgot-password'));
          //     break
          //   /*
          //   case 'MFA_SETUP':
          //     // 'MFA method is TOTP (the one time password) which requires the user to go through some steps to generate those passwords'
          //     break
          //   */
          //   default:
          //     console.log('default');
          //     // completeNewPwd.value = false
          //     // analytics event track:
          //     // TODO:
          //     // this.trackLogin();
          //     // navigateTo(localePath('/'));
          //     // navigateTo(localePath(''));
          //     // await navigateTo(localePath('/'));
          //     await navigateTo(nuxt.$localePath('/'));
          // }
          return this.user;
        };
      } catch (error) {
        console.log('pinia userAuthSignIn error: ', error);
        throw new Error(error);
      } finally {
        this.loading = false;
      }
    },
    async logout() {
      await Auth.signOut();
      this.resetAuth();
      // TODO: google tag: logout ?
    },
    resetAuth() {
      // this.authenticated = false;
      this.setAuthenticated(false);
      this.user = null;
      this.userstatus = null;
      this.userid = null;
      this.username = null;
      this.email = null;
      this.authorizeLevel = 0;
      this.usergroups = [];
      // actionerror: '',
      // actionerrordetails: '',
    }
  },
  // túl nagy süti:
  // persist: {
  //   paths: ['authenticated', 'user', 'userid', 'username', 'email', 'authorizeLevel'],
  //   storage: persistedState.cookiesWithOptions({
  //     path: '/',
  //     maxAge: 60 * 60 * 24 * 7,
  //     domain: process.env.APP_COOKIE_DOMAIN,
  //     sameSite: 'lax',
  //   }),
  // },
  persist: {
    storage: persistedState.localStorage,
  },
});
