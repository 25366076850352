import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// import { fas } from '@fortawesome/free-solid-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';
// library.add(fas, fab);
// library.add(fas);

import { 
  faKey, 
  faTimes, 
  faCheck, 
  faAt, 
  faShieldAlt, 
  faStoreAlt, 
  faQuoteRight, 
  faSearchPlus,
  faAward,
  faTag,
  faBell,
  faShoppingCart,
  faExclamationCircle,
  faExclamationTriangle,
  faEllipsisV,
  faSignOutAlt,
  faBars,
  faUser,
  faArrowCircleUp,
  faEye,
  faLifeRing,
  faSearch,
  faInfoCircle,
  faTruck,
  faMailBulk,
  faCreditCard,
  faCaretRight,
  faArrowLeft,
  faBoxOpen,
  faReceipt,
  faAddressBook,
  faChevronCircleUp,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faThList,
  faCheckCircle,
  faClock,
  faTimesCircle,
  faCalendarAlt,
  faBookmark,
  faMapMarkerAlt,
  faBusinessTime,
  faAngleDown,
  faUserFriends,
  faArrowAltCircleRight,
  faMinusSquare,
  faPlusSquare,
  faHome,
  faFire,
  faTrophy,
  faStopwatch,
  faAngleRight,
  faPhone,
  faHandHoldingUsd,
  faExchangeAlt,
  faArrowAltCircleUp,
  faArrowRight,
  faArrowCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import { faInstagramSquare, faFacebookSquare, faPaypal } from '@fortawesome/free-brands-svg-icons';
library.add( faFacebookSquare, faInstagramSquare, faPaypal );
library.add(
  faKey, 
  faTimes, 
  faCheck, 
  faAt, 
  faShieldAlt, 
  faStoreAlt, 
  faQuoteRight, 
  faSearchPlus,
  faAward,
  faTag,
  faBell,
  faShoppingCart,
  faExclamationCircle,
  faExclamationTriangle,
  faEllipsisV,
  faSignOutAlt,
  faBars,
  faUser,
  faArrowCircleUp,
  faEye,
  faLifeRing,
  faSearch,
  faInfoCircle,
  faTruck,
  faMailBulk,
  faCreditCard,
  faCaretRight,
  faArrowLeft,
  faBoxOpen,
  faReceipt,
  faAddressBook,
  faChevronCircleUp,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faThList,
  faCheckCircle,
  faClock,
  faTimesCircle,
  faCalendarAlt,
  faBookmark,
  faMapMarkerAlt,
  faBusinessTime,
  faAngleDown,
  faUserFriends,
  faArrowAltCircleRight,
  faMinusSquare,
  faPlusSquare,
  faHome,
  faFire,
  faTrophy,
  faStopwatch,
  faAngleRight,
  faPhone,
  faHandHoldingUsd,
  faExchangeAlt,
  faArrowAltCircleUp,
  faArrowRight,
  faArrowCircleRight,
);

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon);
})
