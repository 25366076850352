import { useAuthStore } from "~/store/auth";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    // const authStore = useAuthStore();
    // const { isAuthenticated } = authStore; // have all non reactiave stuff here 

    // async function handleStorageChange(event) {
    //   if (event.key === 'auth' && event.newValue) {
    //     // Frissítsd az authStore állapotát az új értékekkel
    //     // authStore.$patch(JSON.parse(event.newValue));
    //     // await isAuthenticated();
    //     console.log('Storage Changed!')
    //   }
    // }

    // window.addEventListener('storage', handleStorageChange);

    // // Tisztítsd fel az eseménykezelőt, amikor a komponens le van bontva
    // nuxtApp.hook("unmounted", () => {
    //   window.removeEventListener('storage', handleStorageChange);
    // });
  }
});
