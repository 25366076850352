/**
 * 
 * Pinia Store: loading state:
 * 
 * - loading:               Globális (még nem használjuk)
 * - loadingatproductlist:  A terméklista betöltésekhez
 * - loadinginmodal:        A modal ablakok tötlésére (még nem használjuk)
 * 
*/

export const useLoadingStateStore = defineStore('loadingstate', {
  state: () => ({
    loadingstate: false,
    loadingatproductlist: false,
    loadinginmodal: false,
  }),
  getters: {
    getLoading(): boolean {
      return this.loadingstate;
    },
    getLoadingAtProductList(): boolean {
      return this.loadingatproductlist;
    },
    getLoadingInModal(): boolean {
      return this.loadinginmodal;
    },
  },
  actions: {
    setLoading(val: boolean): boolean {
      this.loadingstate = val;
      return this.loadingstate;
    },
    setLoadingAtProductList(val: boolean): boolean {
      console.log('setLoadingAtProductList', val);
      this.loadingatproductlist = val;
      return this.loadingatproductlist;
    },
    setLoadingInModal(val: boolean): boolean {
      this.loadinginmodal = val;
      return this.loadinginmodal;
    },
  },
});