// import { useAuthStore } from '~/stores/auth';

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    // const authStore = useAuthStore();

    // authStore.$subscribe((mutation, state) => {
    //   // Itt kezelheted az állapotváltozásokat
    //   console.log('Az állapot megváltozott:', mutation, state);
    // });
  }
});