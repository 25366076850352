import { Amplify, Auth } from "aws-amplify";
// import { Auth } from '@aws-amplify/auth';
import { defineNuxtPlugin } from '#app'
// import config from "../aws-exports";
export default defineNuxtPlugin(() => {
  const config = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:b1d0c9ef-bc0f-4792-9b66-a7273740fe2f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_og3JUoWuB",
    "aws_user_pools_web_client_id": "69h3en945fhnsilrdk1nq1cdo",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    // "aws_appsync_graphqlEndpoint": "https://5vwfgcja2bf6dohxcjzd5eqeiy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_graphqlEndpoint": "https://api.bestsellers.hu/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "bestsellersapi",
            "endpoint": "https://69ts49hmui.execute-api.eu-west-1.amazonaws.com/test",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "carouselslideimages145243-test",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    // "aws_mobile_analytics_app_id": "269f890b155340c1be3ca92cc5265185",
    // "aws_mobile_analytics_app_region": "eu-west-1",
    // "Analytics": {
    //     "AWSPinpoint": {
    //         "appId": "269f890b155340c1be3ca92cc5265185",
    //         "region": "eu-west-1"
    //     }
    // }
  };
  // const config = useRuntimeConfig()
  // const region = config.public.awsRegion
  // const userPoolId = config.public.userPoolId
  // const userPoolWebClientId = config.public.userPoolWebClientId
  // const cognitoDomainPrefix = config.public.cognitoDomainPrefix
  // const appBaseUrl = config.public.appBaseUrl
  // const amplifyConfig = {
  //   Auth: {
  //     region,
  //     userPoolId,
  //     userPoolWebClientId,
  //     oauth: {
  //       domain: `${cognitoDomainPrefix}.auth.${region}.amazoncognito.com`,
  //       scope: ['openid'],
  //       redirectSignIn: appBaseUrl,
  //       responseType: 'code',
  //     },
  //   },
  // }

  // alap config:
  // Amplify.configure(aws_exports);
  // felülírt config:
  Amplify.configure({
    ...config,
    Auth: {
      ...config,
      // cookieStorage: {
      //   domain: process.env.APP_COOKIE_DOMAIN,
      //   path: "/",
      //   expires: 120,
      //   sameSite: "lax",
      //   secure: process.env.APP_COOKIE_DOMAIN !== "localhost",
      //   // secure: false,
      // },
    },
  });
  return {
    provide: {
      auth: Auth,
    },
  };
})